form .place {
  display: flex;
  margin: 20px 0;
}
form .place svg {
  margin: 4px 12px 0 0;
}
form .place h3 {
  margin: 0;
  font-size: 1.5em;
}
form .place p {
  margin: 2px 0;
}
form .place .hours {
  color: #EBB64C;
}

@media (max-width: 500px) {
  form .place svg {
    margin: 0 12px 0 0;
  }
  form .place h3 {
    font-size: 1.2em;
  }
  form .place p {
    font-size: 0.8em;
  }
}
