header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
header.fixed {
  position: fixed;
}

#logo {
  display: block;
  position: absolute;
  top: -10px;
  left: 30px;
}
#logo img {
  width: 150px;
  border-radius: 50%;
  padding: 8px;
  background-color: white;
}

nav {
  background-color: white;
  height: 100px;
  padding-left: 200px;
  white-space: nowrap;
}

nav button, nav a {
  font-family: 'Oraqle Script';
  font-weight: normal;
  font-size: 28pt;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  padding: 30px;
}

header h1 {
  font-family: "Oraqle Script";
  font-size: 3em;
  margin: 0;
  padding: 26px 0;
  font-weight: normal;
  color: #EBB64C;
  text-align: center;
  margin: 0 20px 0 220px;
}

.social {
  float: right;
  padding: 0 15px;
  display: flex;
  align-items: center;
  height: 100px;
}
.social button, .social a {
  display: inline-block;
  height: 30px;
  width: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 8px;
  color: transparent !important;
  overflow: hidden;
}
.social .facebook {
  background-image: url("/images/fb.png");
}
.social .facebook:hover {
  background-image: url("/images/fb-hover.png");
}
.social .instagram {
  background-image: url("/images/insta.png");
}
.social .instagram:hover {
  background-image: url("/images/insta-hover.png");
}
.social .phone {
  background-image: url("/images/phone.png");
}
.social .phone:hover {
  background-image: url("/images/phone-hover.png");
}
.social .places {
  height: 70px;
  width: 70px;
  background-image: url("/images/map.png");
  margin-left: 15px;
}
.social .places:hover {
  background-image: url("/images/map-hover.png");
}
.social img {
  width: 130px;
  margin-left: 20px;
}

#hamburger, #menu, #overlay {
  display: none;
}


#menu {
  position: fixed;
  top: 0; bottom: 0;
  padding-top: 10vh;
  left: 100vw; right: -80vw;
  background-color: rgb(255, 238, 221);
  z-index: 1;
  transition: .25s ease-in-out;
}
#menu.open {
  left: 20vw;
  right: 0;
}
#menu>button {
  color: #352208;
  font-family: "Oraqle Script";
  text-decoration: none;
  display: block;
  font-size: 3em;
  margin: 4vh 30px;
}
#menu .social {
  position: absolute;
  left: 12px;
  bottom: 12px;
}
#menu .social button, #menu .social a {
  margin-right: 5vw;
  height: 40px;
  width: 40px;
}
#overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color ease-in-out .25s;
  pointer-events: none;
}
#overlay.open {
  background-color: rgba(0, 0, 0, 0.6);
}

/* Menu button for mobile: */

#hamburger {
  width: 40px;
  height: 35px;
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 2;
  padding: 0;
}
#hamburger span {
  display: block;
  position: absolute;
  height: 4px;
  background: white;
  border-radius: 2px;
  opacity: 1;
  left: 5px; right: 5px;
  width: 30px;
  transform-origin: left center;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}
#hamburger.open span {
  background: #352208;
}
#hamburger span:nth-child(1) {
  top: 5px;
}
#hamburger span:nth-child(2) {
  top: 14px;
}
#hamburger span:nth-child(3) {
  top: 23px;
}
#hamburger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 3.5px;
  left: 9px;
}
#hamburger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
#hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 24.5px;
  left: 9px;
}

@media (max-width: 1060px) {
  .social img {
    display: none;
  }
}

@media (max-width: 900px) {
  #shortcuts .phone, #shortcuts .places {
    display: none;
  }
  nav button, nav a {
    padding: 30px 2vw;
  }
}

@media (max-width: 680px) {
  #shortcuts {
    display: none;
  }
}

@media (max-width: 500px) {
  #shortcuts, nav {
    display: none;
  }
  header.fixed {
    position: absolute;
  }
  #logo {
    top: 30px;
  }
  #logo img {
    width: 80px;
    padding: 3px;
  }
  #hamburger, #menu, #overlay {
    display: block;
  }
  #hamburger {
    position: fixed;
  }
  header h1 {
    margin-left: 120px;
    font-size: 2.5em;
  }
}

