form .product {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
form .product:not(:last-child) {
  border-bottom: 1px solid #EBB64C;
}

form .product .photo {
  width: 100px;
  height: 125px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  margin-right: 20px;
}
form .product img {
  width: 100%;
}
form .product .content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
form .product .description {
  flex: 1 1 120px;
  margin-right: 20px;
}
form .product h3 {
  margin: 0;
  font-size: 1.5em;
}
form .product p {
  margin: 2px 0;
  white-space: pre-line;
}
form .product .price {
  color: #EBB64C;
}
form .product .amount {
  white-space: nowrap;
}
form .product .amount span {
  vertical-align: middle;
  font-size: 1.5em;
  display: inline-block;
  width: 50px;
  text-align: center;
}
form .product .amount a {
  user-select: none;
}
form .product .amount a.disabled {
  opacity: 0.6;
}

@media (max-width: 500px) {
  form .product h3 {
    font-size: 1.2em;
  }
  form .product p {
    font-size: 0.8em;
  }
  form .product .description {
    margin: 0 0 5px;
  }
}
