#legal-container {
  background-image: url("/images/home.jpg");
  background-size: 100% auto;
}

#legal-container main {
  padding-top: 40px;
}

@media (max-width: 500px) {
  #legal-container main {
    padding-top: 100px;
  }
}
