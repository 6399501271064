a, button {
  cursor: pointer;
  text-decoration: none;
  color: #352208;
}
a:hover, button:hover {
  color: #EBB64C;
}

button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  text-align: left;
  font: inherit;
}
form button[type="submit"] {
  padding: 10px 15px;
  font-family: 'Oraqle Script';
  font-size: 3em;
}
.disabled {
  color: #352208 !important;
  opacity: 0.6;
}

button svg, a svg {
  vertical-align: middle;
}


/* pages */

main {
  margin-top: 100px;
  min-height: calc(100vh - 100px);
}

section {
  padding: 20px 18%;
}

@media (max-width: 900px) {
  section {
    padding: 20px 10%;
  }
}


@media (max-width: 500px) {
  main {
    margin-top: 0;
  }
  section {
    padding: 20px;
  }
}
