form.contact {
  padding: 5px 0 30px;
  text-align: center;
}

.sent {
  text-align: center;
  margin: 200px 0;
}

form.contact .error {
  color: #C00;
  height: 20px;
  margin: 15px 0 0;
}

form.contact button[disabled] {
  color: rgb(130, 113, 93);
}
