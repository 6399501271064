#home-container {
  background-color: #EBB64C;
}

#splash {
  height: calc(100vh - 100px);
  padding: 0;
  position: relative;
  background-size: cover;
  background-position: center 70%;
}

#video, #player, #content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#video {
  overflow: hidden;
}
#player {
  pointer-events: none;
}

#content {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#content h1 {
  font-weight: bolder;
  font-size: 6em;
  margin: 0;
  line-height: 0.85em;
  /*text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);*/
}

#content h1 span {
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}

#content #location {
  margin-top: 5vh;
  font-family: "Oraqle Script";
  font-size: 4em;
  background-image: url("/images/location-bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px 0 0 15px;
}

#content button {
  margin: 5vh 0 -15vh;
  align-self: center;
}

#concept, #order, #contact {
  background-image: url("/images/home.jpg");
  background-size: 100% auto;
}

#home-container h2 {
  font-weight: bolder;
  font-size: 2em;
  text-align: center;
  margin: 20px 0;
  line-height: 1em;
}

#home-container h2 span {
  font-weight: 300;
  font-size: 0.8em;
  text-transform: uppercase;
}

#concept h2 {
  font-size: 3em;
  text-align: left;
  margin: 1em 0 0.8em;
}

#concept h3 {
  font-size: 1.2em;
}

#concept-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#concept-text {
  flex: 1;
  margin-right: 30px;
  min-width: 250px;
}

#concept-text img {
  display: none;
  width: 130px;
  text-align: center;
  align-self: center;
}

#gallery-container {
  position: relative;
  flex: 0 0 333px;
  padding: 25px 0 40px;
}
#gallery {
  overflow: hidden;
  border-radius: 50%;
  width: 333px;
  height: 333px;
}
#gallery-content {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  transition: transform .4s ease-in-out;
}
#gallery img {
  display: inline-block;
  width: 100%;
  height: 100%;
}
#gallery-controller {
  position: absolute;
  right: 0;
}
#gallery-controller button {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin: 5px;
  border-radius: 50%;
  background-color: #352208;
}
#gallery-controller button.active, #gallery-controller button:hover {
  background-color: #EBB64C;
}

#products {
  padding: 0;
}
#products h2 {
  margin-top: 1.5em;
}
#products h2 span {
  font-size: 0.75em;
  opacity: 0.8;
}
.product-gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 30px 0 0;
  justify-content: center;
}
.product-gallery li {
  list-style: none;
  background-color: rgb(255, 238, 221);
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
}
/* https://stackoverflow.com/a/29308467/1052033 */
.product-gallery li:before {
  content: "";
  float: left;
  padding-top: 125%; /* TODO: flexible image ratio */
}
.product-gallery .caption {
  cursor: default;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  height: 100%;
  background-color: rgba(53, 34, 8, 0.7); /* #352208 + alpha */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  user-select: none;
}
.product-gallery .description {
  font-size: 0.9em;
  white-space: pre-line;
}
.product-gallery .caption:hover {
  opacity: 1;
}
.product-gallery h3 {
  font-family: "Oraqle Script";
  font-size: 2.5em;
  font-weight: normal;
  margin: 10px 0;
}
.product-gallery hr {
  width: 40%;
  border: none;
  border-bottom: 1px solid white;
}
.product-gallery p {
  margin: 5px 0;
}
.product-gallery p:last-child {
  margin-bottom: 15px;
}

#order {
  text-align: center;
  padding-bottom: 50px;
}
#order .row {
  display: flex;
  text-align: left;
  align-items: center;
  margin: 7px 0;
  padding: 40px 0;
}
#order .row:not(:last-child) {
  border-bottom: 2px solid #EBB64C;
}
#order .action {
  display: flex;
  flex: 0 0 210px;
  justify-content: space-between;
  padding-right: 40px;
  text-align: center;
}
#order .large {
  flex-basis: 280px;
}
#order .row h3 {
  margin: 2px 0;
}
#order .row p {
  margin: 0;
}

#order .date {
  width: 60px;
}
#order .action a {
  color: rgb(255, 238, 221);
  line-height: 1.25em;
  display: block;
  height: 84px;
  font-size: 1.3em;
  background-color: #352208;
}
#order .action a:hover {
  color: rgb(255, 238, 221);
  background-color: #EBB64C;
}
#order .action a span {
  font-size: 1.5em;
  vertical-align: bottom;
}
#order a.subscribe {
  font-family: "Oraqle Script";
  font-size: 4em;
  padding: 0 25px;
  width: 100%;
}
#order p.deadline {
  margin: 5px 0 -5px;
  font-size: 0.7em;
  height: 0;
  font-weight: lighter;
}
#order p.deadline strong {
  font-weight: normal;
}

#products, #places {
  color: white;
}
#places .columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
#places .columns>div {
  flex: 0 1 45%;
}
#places .row {
  display: flex;
  min-height: 100px;
  margin-top: 20px;
}
#places img {
  width: 80px;
}
#places h3 {
  font-weight: lighter;
  margin: 0 20px 0 20px;
  min-width: 150px;
}
#places h3 strong {
  font-weight: normal;
}
#places ul {
  margin: 30px 0;
  padding-left: 20px;
}
#places li {
  margin: 20px 0;
  font-weight: lighter;
}
#places li strong {
  font-weight: normal;
}
#places li.home {
  border-top: 1px solid white;
  padding-top: 20px;
  list-style: none;
  margin: 0 0 0 -20px;
}
#places .both {
  align-items: center;
  justify-content: center;
  border-top: 4px solid white;
  padding: 20px 0 10px;
}
#places .both img {
  width: 50px;
}
#places .both h3 {
  font-size: 1.4em;
}

#gmap {
  width: 100%;
  height: calc(100vh - 100px);
}

#gmap h3 {
  margin: 0;
}
#gmap p {
  margin: 5px 0 0;
}

@media (max-width: 1060px) {
  #concept-text img {
    display: block;
  }
}

@media (max-width: 900px) {
  #splash h1 {
    font-size: 4em;
  }
  #concept h2 {
    font-size: 2em;
  }
  #product-types {
    padding: 40px 10%;
  }
}

@media (max-width: 700px) {
  #places .columns>div {
    flex: 1 1 50%;
    max-width: 450px;
  }
}

@media (max-width: 500px) {
  #splash {
    height: calc(100vh - 40px);
  }
  #splash h1 {
    font-size: 2em;
  }
  #splash #location {
    font-size: 2em;
    padding: 5px 0 0 8px;
  }
  #splash button {
    margin-bottom: -25vh;
  }
  #concept h2 {
    font-size: 1.5em;
  }
  #concept h3 {
    font-size: 1em;
  }
  #concept p {
    font-size: 0.9em;
  }

  #gallery-container {
    flex: 0 0 100vw;
  }
  #gallery {
    width: 100vw;
    height: 100vw;
    border-radius: 0;
  }
  #gallery-controller {
    position: initial;
    text-align: center;
  }
  #gallery-controller button {
    display: inline-block;
    margin: 20px 10px 0;
  }

  .product-gallery .caption {
    cursor: pointer;
    font-size: 0.8em;
  }

  #order {
    padding-bottom: 0;
  }
  #order .row {
    flex-wrap: wrap;
    padding: 20px 0;
  }
  #order .action {
    order: 1;
    padding: 20px 0;
    flex: 0 0 220px;
    flex-wrap: wrap;
  }
  #order .large {
    flex-basis: auto;
  }
  #order .date {
    margin-right: 10px;
  }
  #order .dates {
    flex-basis: 230px;
  }
  #order .large.dates {
    flex-basis: 170px;
  }
  #order p.deadline {
    height: auto;
    margin-bottom: 20px;
  }

  #gmap {
    height: 100vh;
  }
}


/* product-gallery sizes */

.product-gallery li {
  flex: 0 1 20%; /* i.e. max 5 per row */
  height: 27vw;
}

@media (max-width: 1000px) {
  .product-gallery li {
    flex: 0 1 25%; /* i.e. max 4 per row */
    height: 33vw;
  }
}

@media (max-width: 700px) {
  .product-gallery li {
    flex: 0 1 33.33333%; /* i.e. max 3 per row */
    height: 44vw;
  }
}

@media (max-width: 500px) {
  .product-gallery li {
    flex: 0 1 50%; /* i.e. max 2 per row */
    height: 65vw;
  }
  .product-gallery .description {
    white-space: normal;
  }
}
