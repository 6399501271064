form .duration {
  margin: 20px 0;
}
form .duration span {
  vertical-align: middle;
}
form .duration button {
  margin: 0 10px;
  user-select: none;
}
form .duration .value {
  font-size: 1.5em;
}
