footer {
  padding: 20px;
  text-align: center;
  background-color: #352208;
  font-size: 0.8em;
}
footer a {
  color: #FFEEDC;
}
footer a:hover {
  color: #FFEEDC;
}
