form .checkbox p {
  margin: 0;
}
form .checkbox {
  display: block;
  margin: 10px 0;
}
form .checkbox span {
  vertical-align: middle;
  margin-left: 12px;
}
/*
form .checkbox:hover * {
  color: initial;
}
*/
