/* container */

form .input {
  position: relative;
  margin: 10px 0;
}
form .textarea {
  margin-top: 15px;
}

/* actual text field */

input, textarea {
  background: transparent;
  border: none;
  color: #352208;
  width: 100%;
  font-size: 1em;
  /* iOS webkit reset: */
  border-radius: 0;
  -webkit-appearance: none;
}
input {
  border-bottom: 1px solid #352208;
  line-height: 1.5em;
  margin: 10px 0;
  /* normalize: */
  padding: 2px 0;
}
textarea {
  border: 1px solid #352208;
  line-height: 1.2em;
  height: 20vh;
  margin: 15px 0 0;
}
input:focus, textarea:focus {
  outline: none;
}

/* remove the yellow autofill background in Chrome: https://stackoverflow.com/a/29350537/1052033 */
/*
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 0s ease 1000000000s;
}
*/

/* remove the red border in Firefox: https://stackoverflow.com/a/6189446/1052033 */
input:required, input:invalid, textarea:required, textarea:invalid {
  box-shadow: none;
}


/* label */

form .input span {
  position: absolute;
  top: 14px;
  left: 0;
  transform: scale(1) translate(0px, 0px);
  transform-origin: left top 0px;
  transition: transform 450ms cubic-bezier(0.23, 1, 0.32, 1);
  color: #352208;
  user-select: none;
  pointer-events: none;
  height: 20px;
}
form .textarea span {
  transform: scale(1) translate(5px, 4px);
}
form .focus span, .textarea.focus span {
  transform: scale(0.75) translate(0px, -25px);
}
form input:-webkit-autofill + span {
  transform: scale(0.75) translate(0px, -25px);
}
