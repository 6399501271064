form .days p {
  margin: 0;
}
form .day {
  display: block;
  margin: 10px 0;
}
form .day span {
  vertical-align: middle;
  margin-left: 12px;
}
