#order-container {
  background-image: url("/images/home.jpg");
  background-size: 100% auto;
}

.order {
  margin-top: 20px;
}

.order h2 {
  font-size: 2em;
  text-align: center;
}

.total {
  background-color: #352208;
}

#total {
  text-align: center;
  font-size: 2em;
  color: white;
  font-weight: 300;
  padding: 10px;
}
#total span {
  font-weight: bold;
}

.customer {
  background-color: #EBB64C;
  padding-top: 40px;
  padding-bottom: 40px;
}

p.warn {
  text-align: center;
}
p.error {
  color: #C00;
  text-align: center;
}
section.end {
  padding: 30vh 20px;
  text-align: center;
}

form .submit {
  text-align: center;
}
.customer button:enabled:hover {
  color: #FFEEDC;
}
form .submit span {
  display: inline-block;
  color: white;
  font-size: 0.9em;
  border-top: 1px solid white;
  padding: 10px 0;
  margin-top: 5px;
}
form .submit img {
  width: 30px;
  vertical-align: -6px;
  margin-right: 10px;
}
form .submit button[disabled] {
  color: rgb(121, 93,45);
}

@media (max-width: 500px) {
  #order-container {
    padding-top: 80px;
  }
  .order h2 {
    font-size: 1.5em;
  }
}
